.actionDrawerContainer {
  display: none;
  position: relative;
  width: 100%;
  max-width: 170px;
  margin: 0 28px 0 auto;
  align-items: center;
  justify-content: flex-end;

  @media (--min-width-large) {
    display: flex;
  }

  @media (--min-width-xxlarge) {
    margin-right: 75px;
  }

  :global(html.discord) & {
    display: flex;
    width: auto;
    margin: 0;
    margin-left: 0.5rem;
    max-width: unset;

    &.noGuideId {
      margin-right: 16px;
    }

    @media (--min-width-small) {
      margin-left: 1rem;
    }
  }

  :global(html.discord.mobile) & {
    height: var(--player-components-height);
    order: 0;
    margin: 0 16px;
  }
}

.spacer {
  display: flex;
  justify-content: center;
  width: 25%;
  vertical-align: baseline;
  flex-shrink: 1;

  :global(html.discord) & {
    width: auto;
    margin-right: 8px;

    @media (--min-width-medium) {
      margin-right: 24px;
    }
  }
}

.actionDrawerIconContainer {
  background-color: var(--content-area-background-color-hex);
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0;
  transition: var(--base-transition);
  cursor: pointer;

  &:hover {
    path {
      stroke: var(--knighthood-grey);
    }
  }

  :global(html.discord) & {
    background-color: transparent;
    width: 28px;
    height: 28px;

    svg {
      width: 28px;
      height: 28px;
    }

    path {
      stroke: var(--anti-flash-white);
    }
  }

  :global(html.ford) & {
    background-color: transparent;
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.speedControlSpacer,
.favoriteSpacer {
  :global(html.discord) & {
    display: none;

    @media (--min-width-xsmall) {
      display: block;
    }
  }
}

.speedControlSpacer {
  :global(html.discord) & {
    width: 35px;
    display: none;

    @media (--min-width-small) {
      display: block;
    }

    > div {
      display: flex;
      justify-content: center;
    }
  }
}

.speedControlContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  margin-right: 7px;

  :global(html.discord) & {
    width: auto;
    margin-right: 0;
    padding: 0;
  }
}

.volumeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
