.container {
  height: 100%;
  background: #fff url('/assets/img/default-item-v2.png') no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 6px;
  overflow: hidden;

  .image {
    background-color: #fff;
  }

  :global(html.discord) & {
    background: #0000;

    .image {
      background-color: #0000;
    }
  }
}
