@mixin artworkStyles() {
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(28 31 58 / 18%);
  transition: transform 250ms;
}

@mixin activeStation() {
  position: relative;
  z-index: 2;
  transform: translateX(22px) scale(1);

  @media (--min-width-small) {
    transform: translateX(36px) scale(1);
  }
}

@mixin inactiveStation() {
  position: absolute;
  transform: scale(0.65);
  z-index: 1;
}

.link {
  position: relative;
  display: block;
  width: 100%;
  height: var(--player-height);
  overflow: hidden;

  :global(html.discord) & {
    height: 100%;
  }
}

.link::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    to right,
    rgb(236 237 238 / 0%),
    rgb(241 242 243 / 90%)
  );
  width: 10px; /* this value should be equal to GRADIENT_WIDTH in NowPlaying.js */
  height: 100%;

  :global(html.discord) & {
    display: none;
  }
}

.titlesContainer {
  position: relative;
  width: 100%;

 :global(html.ford) & {
    text-align: right;
  }
}

.scrollContainer {
  position: absolute;
  top: 50%;
  transform: translate(
    0,
    -50%
  ); /* base style for JS animation in NowPlaying.js */

  :global(html.ford) & {
    right: 0px;
  }
}

.artworkContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  background-color: var(--content-area-background-color-hex);
  overflow: hidden;
  box-sizing: content-box;

  :global(html.discord) & {
    background-color: var(--medium-grey);
  }
}

.boostExperienceContainer {
  width: 64px;
  height: 100%;
  position: relative;

  @media (--min-width-small) {
    width: 102px;
  }

  .boostCircleIcon {
    display: none;
  }

  &:hover {
    .boostCircleIcon {
      display: unset;
      position: absolute;
      z-index: 3;
      right: 29px;
      width: 16px;

      @media (--min-width-small) {
        right: 34px;
        width: 26px;
      }
    }
  }
}

.nowPlayingContainer {
  display: flex;
  align-items: center;
  height: 100%;

  :global(html.ford) & {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}

.nowPlayingScrollSpacer {
  background-color: var(--content-area-background-color-hex);
  z-index: 5;

  :global(html.discord) & {
    background-color: var(--medium-grey);
  }
}

.boostStationArtwork {
  @include artworkStyles;
  @include inactiveStation;

  &.activeStation {
    @include activeStation;
  }
}

.directoryStationArtwork {
  @include artworkStyles;
  @include activeStation;

  &.inactiveStation {
    @include inactiveStation;
  }
}

.artworkImage {
  @include artworkStyles;

  box-shadow: none;
  display: block;
  max-width: 40px;

  @media (--min-width-small) {
    max-width: 56px;
  }

  :global(html.discord) & {
    max-width: 40px;
  }

  :global(html.discord.mobile) & {
    max-width: 66px;
  }

  :global(html.ford) & {
    max-width: 70px;
  }
}

.boostStationImage,
.directoryStationImage {
  width: 42px;

  @media (--min-width-small) {
    width: 66px;
  }
}

.title {
  padding-top: 2px;
  color: var(--secondary-color-5);
  line-height: 19px;
  font-size: var(--font-size-2);
  white-space: nowrap;

  :global(html.discord) & {
    font-size: var(--font-size-1);
    color: var(--text-color);
  }

  :global(html.discord.mobile) & {
    font-size: var(--font-size-2);
  }

  :global(html.ford) & {
    font-size: var(--font-size-3);
  }
}

.subtitle {
  font-size: var(--font-size-1);
  font-family: var(--mono-font);
  line-height: 16px;
  color: var(--cloud-grey);
  white-space: nowrap;

  :global(html.discord) & {
    font-size: 10px;
  }

  :global(html.discord.mobile) & {
    font-size: var(--font-size-1);
  }

  :global(html.ford) & {
    font-size: var(--font-size-2);
    line-height: 1.5;
  }
}
