.copyUrlButton {
  height: 35px;
  display: block;
  margin: auto;
}

.panelHeader {
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 451px;
  padding: 16.5px 20px;
  background-color: #fff;

  /* Font */
  color: var(--secondary-color-1);
  font-size: var(--font-size-4);
}

.stationInfoContainer {
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
}

.stationInfoContainer img {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 20px;
  border: 0.5px solid rgb(214 214 214);
  border-radius: 4px;
}

.stationName {
  padding-top: 10px;
  line-height: 24px;
  color: var(--secondary-color-1);
  font-size: var(--font-size-3);

  /* Limit to two lines */
  max-height: 58px;
  overflow: hidden;
}

.stationGenre {
  line-height: 24px;
  color: var(--secondary-color-4);
  font-size: var(--font-size-2);
  
  /* Truncate with ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iframeContainer {
  height: 40px;
  padding: 6px 0 6px 6px;
  background-color: var(--modal-input-color);
  border: solid 1px rgb(28 32 60 / 20%);
  border-radius: 2px;
  margin-bottom: 10px;

  @media (--min-width-xxsmall) {
    margin-bottom: 20px;
  }
}

.iframeContainer > input {
  width: 100%;
  height: 28px;
  padding: 6px 0 3px 7px;
  overflow-y: scroll;
  background-color: #b2d7ff;
  line-height: 19px;
  font-size: 13px;
  color: #858585;
  cursor: pointer;
  border: none;
}

.iframeContainer > input:focus {
  outline: none;
}

.shareUrlInput {
  position: absolute;
  z-index: -1;
  height: 0;
  opacity: 0;
  cursor: default;
}

.dialogXButton {
  top: 18px;
  right: 18px;
}

.iconContainer {
  text-align: center;
}

.shareIcon {
  display: inline-flex;
  align-items: center;
  margin: 0 5px 10px;
  width: 32px;
  height: 32px;

  @media (--min-width-xsmall) {
    margin: 0 10px 20px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.8;
  }
}

.facebook {
  background: transparent url('/assets/img/shared/facebook.svg') no-repeat;
  background-size: contain;
}

.tumblr {
  background: transparent url('/assets/img/shared/tumblr.svg') no-repeat;
  background-size: contain;
}

.twitter {
  background: transparent url('/assets/img/shared/twitter.svg') no-repeat;
  background-size: contain;
}
