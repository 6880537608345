.playerContainer {
  display: flex;
  position: absolute;
  z-index: var(--top-z-index);
  bottom: 0;
  width: 100%;
  height: var(--player-height);
  transition: var(--base-transition);
  background-color: var(--content-area-background-color-hex);
  
  &.withBoxShadow {
    box-shadow: var(--fade-box-shadow);
  }

  &.playerHighestIndex {
    z-index: var(--above-top-z-index);
  }

  :global(html.discord) & {
    display: none;
    height: var(--player-height);
    background-color: var(--medium-grey);
    justify-content: space-between;
    transform: translateY(1px);

    @media (--min-width-xxsmall) {
      display: flex;
    }

    &.noGuideId {
      justify-content: flex-end;
    }
  }

  :global(html.discord:not(.mobile)) & {
    z-index: var(--above-top-z-index);
  }


  :global(html.ford) & {
    height: var(--player-height);

    &.noGuideId {
      display: none;
    }
  }
}

.desktopPlayerContainer {
  z-index: var(--above-top-z-index);
}

.playerInner {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.leftSection {
  display: flex;
  flex-grow: 1;
  min-width: 30%;

  :global(html.discord.mobile) & {
    height: var(--player-components-height);
    flex-grow: 1;
  }

  @media (--min-width-medium) {
    flex-grow: 0;
  }

  @media (--min-width-large) {
    padding-right: 10px;
  }

  @media (--min-width-xxlarge) {
    min-width: 22.7%;
  }

  @media (--min-width-xxxlarge) {
    min-width: 26.1%;
  }

  :global(html.ford) & {
    min-width: 75%;
    order: 2;
    flex-grow: 1;
  }
}

.rightSection {
  @media (--min-width-small) {
    display: flex;
    flex: 1;
  }

  @media (--min-width-medium) {
    width: 67%;
  }

  @media (--min-width-xxlarge) {
    width: 87.3%;
  }

  @media (--min-width-xxxlarge) {
    width: 73.9%;
  }
}

.nowPlayingContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.boostNextPlayIconContainer {
  cursor: pointer;
  height: var(--player-height);
  padding-top: 12px;

  @media (--min-width-small) {
    padding-top: 26px;
  }

  @media (--min-width-medium) {
    position: absolute;
    height: unset;
    padding-top: unset;
    left: calc(50% + 46px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
  }
}

.controlsContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (--min-width-medium) {
    flex-direction: column;
    flex-grow: 1;
  }

  // relative value used to offset the controls for *visual* centering with content area
  @media (--min-width-xxlarge) {
    margin-right: 15%;
  }

  // relative value used to offset the controls for *visual* centering with content area
  @media (--min-width-xxxlarge) {
    margin-right: 20%;
    width: 56%;
  }

  :global(html.discord) & {
    height: 100%;
    max-width: 300px;
    margin: 0;
    flex-direction: column;
    flex-grow: 1;

    @media (--min-width-small) {
      margin: 0 8px;

      &:not(.isLive, .failed) {
        margin-top: 6px;
      }
    }

    @media (--min-width-large) {
      position: absolute;
      left: 50%;
      width: 100%;
      max-width: 300px;
      margin: 0;
      transform: translateX(-50%);
    }

    &.failed {
      justify-content: space-around;
      margin: 2px 0;
    }
  }

  :global(html.discord.mobile) & {
    height: var(--player-components-height);
    order: 1;
    flex-grow: 0;
    margin-right: 16px;
  }

  :global(html.ford) & {
    margin-top: 0 !important;
    margin-left: 16px;
    flex-grow: 0;

    @media (--min-width-large) {
      position: relative;
      left: initial;
      width: calc(40px + 16px);
      max-width: none;
      transform: none;
    }

    &.failed {
      justify-content: space-around;
      margin: 0 0 0 16px;
    }
  }

  .messageContainer {
    display: none;

    @media (--min-width-small) {
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
}

.controls {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-top: 5px;

  @media (--min-width-medium) {
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  :global(html.discord) & {
    margin-top: 0;
    justify-content: center;
    flex-grow: 0;
  }
}

.rewindContainer {
  cursor: pointer;
  display: none;
  margin-right: 24px;

  @media (--min-width-medium) {
    display: block;
  }

  :global(html.discord) & {
    top: 13px;
    height: 28px;
    margin-right: 12px;

    @media (--min-width-small) {
      display: block;
    }

    path:nth-child(1),
    path:nth-child(2) {
      stroke: var(--anti-flash-white);
    }

    path:nth-child(3),
    path:nth-child(4) {
      fill: var(--anti-flash-white);
    }
  }
}

.playButtonContainer {
  order: 1;
  height: var(--player-height);
  width: 81px;
  padding: 8px 25px 0 15px;
  text-align: center;
  z-index: 15;

  &:hover {
    cursor: pointer;
  }

  @media (--min-width-small) {
    padding: 22px 32px 0 16px;
    width: 93px;
  }

  @media (--min-width-medium) {
    order: 0;
    padding: 0;
    width: var(--player-play-btn-size);
    height: var(--player-play-btn-size);
  }

  :global(html.discord) & {
    height: auto;
    width: auto;
    padding: 0;

    &.isDisabled svg {
      opacity: var(--lighter-disabled-opacity-value);
    }

    @media (--min-width-small) {
      order: 0;
      padding: 0;
    }
  }
}

.playButton {
  :global(html.discord) & {
    width: 28px !important;
    height: 28px !important;

    &.isLive {
      width: 40px !important;
      height: 40px !important;
    }

    &.failed {
      width: 32px !important;
      height: 32px !important;
    }

    svg {
      width: 100%;
      height: 100%;

      circle {
        stroke: var(--anti-flash-white);
      }
    }

    path {
      fill: var(--anti-flash-white);
    }

    rect {
      fill: var(--secondary-color-5);
    }
  }

  :global(html.discord.mobile) & {
    path {
      fill: var(--ink-extra-light);
    }

    rect {
      fill: var(--anti-flash-white);
    }
  }

  :global(html.ford) & {
    width: 40px !important;
    height: 40px !important;

    &.failed {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.scrubberContainer {
  display: none;

  @media (--min-width-small) {
    display: block;
    width: 100%;
  }
}

.scrubber {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  position: relative;
  margin-bottom: 11px;

  @media (--min-width-medium) {
    display: flex;
    padding: 0 40px;
  }

  :global(html.discord) & {
    margin-bottom: 3px;
    padding: 0;
    flex-grow: 1;

    @media (--min-width-small) {
      display: flex;
    }
  }
}

.fastForwardContainer {
  cursor: pointer;
  margin-left: 24px;

  :global(html.discord) & {
    top: 13px;
    height: 28px;
    margin-left: 12px;
    margin-right: 3px;
    flex-shrink: 0;
    display: none;

    @media (--min-width-small) {
      margin-right: 0;
      display: block;
    }

    path:nth-child(1),
    path:nth-child(2) {
      stroke: var(--anti-flash-white);
    }

    path:nth-child(3),
    path:nth-child(4) {
      fill: var(--anti-flash-white);
    }
  }
}

.actionsSpacer {
  display: none;

  @media (--min-width-xxlarge) {
    display: flex;
    width: 10%;
  }

  @media (--min-width-xxxlarge) {
    width: 20%;
  }
}

.actionsContainer {
  display: none;

  @media (--min-width-large) {
    display: flex;
    width: 25%;
  }

  @media (--min-width-xxlarge) {
    width: 29%;
    max-width: 321px;
  }

  @media (--min-width-xxxlarge) {
    width: 24%;
  }
}

.informational {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-2);
  line-height: 12px;
  color: var(--text-color);
}

.errorMessageContainer {
  font-size: var(--font-size-2);
  margin-left: 8px;

  :global(html.discord) & {
    font-size: 10px;
    margin-left: 4px;
  }

  :global(html.ford) & {
    font-size: var(--font-size-3);
  }
}

.hide {
  display: none;
}

.hideSmall {
  display: none;

  @media (--min-width-small) {
    display: inherit;
  }
}

.hideMedium {
  display: none;

  @media (--min-width-medium) {
    display: inherit;
  }
}

.messageContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;

  :global(html.discord) & {
    flex-grow: 0;
  }
}

.playerMessage {
  position: absolute;
}

.leftErrorMessageContainer {
  display: flex;
  align-self: center;
  margin-left: 24px;
  padding-right: 8px;

  @media (--min-width-small) {
    display: none;
  }

  :global(html.ford) & {
    display: flex;
  }
}
